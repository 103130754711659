#ergebnis {
  .q-head {
    display: block;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 30px 0 30px;
    .divider {
      height: 2px;
      width: 300px;
      background: $turqouise;
      margin: 10px 0 20px 0; }
    h2 {
      font-size: 2em;
      text-transform: uppercase;
      color: $grau;
      width: 100%; }
    @media (max-width: 300px) {
      padding: 15px 20px;
      .divider {
        margin: 15px 0 0 0; }
      h2 {
        margin-bottom: 10px;
        max-width: 100%; } } }
  #ergebnisContent {
    .resultDescription {
      @media (max-width: 300px) {
        padding: 0 15px 15px; }
      &.sportlich {
 }        // background: #c2c495

      width: 100%;
      height: auto;
      position: relative;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      background: #fff;
      h1 {
        font-size: 2.4em;
        color: $dgrau;
        text-transform: uppercase;
        max-width: 55%; }
      p {
        max-width: 55%;
        margin-top: 10px;
        color: $dgrau;
        line-height: 1.8; }
      img {
        position: absolute;
        left: 60%;
        top: 50%;
        transform: translate(0,-50%);
        height: 200px; } }
    .resultGlasses {
      background: white;
      padding: 30px;
      box-shadow: 0 0 20px rgba(0,0,0,0.2);
      position: relative;
      h3 {
        font-size: 1.7em;
        color: $grau;
        text-transform: uppercase;
        text-align: center;
        @media (max-width: 300px) {
          font-size: 1.2em; } }
      .resultList {
        list-style: none;
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        overflow-x: scroll;

        li {
          min-width: 310px;
          text-align: center;
          padding: 20px;
          color: $grau;
          img {
            max-width: 100%;
            max-height: 150px; }
          .divider {
            height: 2px;
            width: 50%;
            background: $turqouise;
            margin: 20px auto; }
          @media (max-width: 300px) {
            min-width: 0;
            .divider {
              margin: 5px auto; } } } } } } }

@media (max-width: 680px) {
  #ergebnis {
    #ergebnisContent {
      .resultDescription {

        height: auto;
        h1, p {
          max-width: 100%; }
        h1 {
          font-size: 1.6em; }
        img {
          display: none; } }
      .resultGlasses {
        overflow-y: scroll;
        padding: 20px 10px;
        h3 {
          text-align: center; }
        .resultList {
          display: flex;
          margin-top: 20px;
          overflow: visible;

          li {
            display: block;
            padding: 10px;
            text-align: center;
            .image-wrapper {
              width: 100%; }

            p {
              text-align: center;
              strong {
                font-size: 0.925em;
                margin-left: 15px; } } } } } } } }
