.content {
  max-width: 1000px;
  height: 800px;
  background: #edf0f2;
  margin: 0 auto;
  border: 1px solid lightgrey;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  scroll-behavior: smooth;
  .bullets {
    width: 100%;
    height: 30px;
    background: white;
    position: absolute;
    bottom: 0;
    text-align: center;
    //display: none
    .bullet-empty {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid $grau;
      display: inline-block;
      margin: 10px 5px; }
    .bullet-full {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $grau;
      display: inline-block;
      margin: 10px 5px; } } }



section[class*="frage"] {
  width: 100%;
  margin: 0 auto;
  //display: none
  position: relative;


  .q-head {
    display: block;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0 0 30px;
    .divider {
      height: 2px;
      width: 300px;
      background: $turqouise;
      margin: 10px 0 20px 0; }
    @media (max-width: 300px) {
      padding: 15px 20px;
      .divider {
        margin: 10px 0 0 0; } }
    h2 {

      font-size: 2em;
      text-transform: uppercase;
      color: $grau;
      width: 100%; } }


  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 20px 0;
    height: 680px;
    li {
      min-width: 100px;
      max-width: 290px;
      width: 30%;
      margin: 10px;

      div {
        cursor: pointer;
        transition: all 300ms ease;
        img {
          max-width: 100%;
          box-shadow: 3px 3px 20px rgba(0,0,0, 0.3), 0 0 6px rgba(0,0,0,0.4);
          transition: all 300ms ease; }
        &:hover {
          img {
            box-shadow: 0px 0px 5px rgba(0,0,0, 0.4); } }
        &:active {
          img {
            opacity: 0.5; } } } } }

  button {
    padding: 10px 20px;
    border: none;
    background: orangered;
    font-size: 16px;
    color: white;
    cursor: pointer; } }


.ergebnis {
  top: 0;
  max-width: 900px;
  margin: 30px auto; }




#sehtyp {
  position: fixed;
  right: 0;
  top: 0;
  background: lightgrey;
  max-width: 300px;
  padding: 30px;
  height: 100vh;
  h1 {
    margin-bottom: 30px;
    font-size: 2em; }
  p {
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    word-spacing: normal; } }

.frage2,
.frage3,
.frage4,
.frage5 {
 }  //display: none

.frage {
  text-align: center;
  .subtitle {
    margin: 20px auto;
    padding: 6px 13px;
    border: solid 2px $grau;
    color: $grau;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    @media (max-width: 300px) {
      font-size: 1em; } } }


@media (max-width: 600px) {
  section[class*="frage"] {
    .q-head {
      padding-right: 30px;
      h2 {
        font-size: 1.6em;
        line-height: 1.4;
        @media (max-width: 300px) {
          font-size: 1.2em; } } }
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding: 20px 0;
      height: auto;
      li {
        width: 37%;
        max-width: 185px;
        @media (max-width: 300px) {
          width: 42%;
          margin: 5px;
          img {
            box-shadow: 1px 1px 5px rgba(black, 0.1)!important;
            display: block; } } } } }
  #q7, #male, #female {
    ul {
      //display: block
      li {
        width: 45%;
        max-width: 220px;
        margin: 10px auto;
        .subtitle {
          font-size: 1em; } } } } }
