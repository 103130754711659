.modal {
    background: rgba(black, 0.8);
    position: fixed;
    width: 100%;
    max-width: 1000px;
    height: 100vh;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    opacity: 0;
    visibility: hidden;
    //pointer-events: none
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease;
    padding: 10px;

    .modal--content {
        transition: all 500ms ease;
        background: #fff;
        padding: 60px 40px 40px;
        border: 1px solid lightgrey;
        position: relative;
        transform: translateY(-100vh);
        max-width: 360px;
        @media (max-width: 300px) {
            padding: 60px 20px 20px 20px; }
        #close {
            appearance: none;
            all: unset;
            border: none;
            outline: none;
            border-radius: none;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            padding: 5px;
            transition: all 300ms ease;
            color: $turqouise;
            &:hover {
                color: orangered; } }
        form {
            min-width: 240px;
            width: 100%;
            @media (max-width: 300px) {
                min-width: 100px; }
            input[type="email"] {
                appearance: none;
                all: unset;
                display: block;
                text-align: center;
                width: 100%;
                padding: 5px 10px;
                border-radius: 0;
                border: 1px solid lightgrey;
                font-size: 0.825em;
                box-sizing: border-box;
                &:focus {
                    border-color: $turqouise;
                    color: $turqouise; } }
            input[type="submit"] {
                all: unset;
                appearance: none;
                font-size: 0.925em;
                text-transform: uppercase;
                display: block;
                padding: 5px 10px;
                background: $turqouise;
                border: darken($turqouise, 5%) 1px solid;
                border-radius: 4px;
                color: white;
                cursor: pointer;
                margin: 20px 0 0 auto;
                position: relative;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
                font-weight: 600; } } }
    &.active {
        opacity: 1;
        visibility: visible;
        .modal--content {
            transform: translateY(0); } } }
