#homescreen {
  padding: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .home-images {
    width: 40%;
    border-right: 2px solid $grau;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
    .mobile {
      display: none; } }
  .hometext {
    width: 100%;
    padding: 0 40px;
    text-align: center;
    img {
      max-width: 100%;
      height: auto; }
    .mobile {
      display: none; }

    h3 {
      color: $grau;
      font-size: 1.6em;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 2px solid $turqouise;
      display: inline-block; }
    h1 {
      color: $grau;
      font-size: 2.8em;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-weight: 800;
      line-height: 1.2;
      margin: 40px auto;
      max-width: 100%;
      text-align: center; }
    button.btn-start {
      color: white;
      position: relative;
      background: linear-gradient($turqouise, darken($turqouise, 8%));
      border: 1px solid darken($turqouise, 5%);
      padding: 10px 20px;
      font-size: 1.4em;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: 700;
      cursor: pointer;
      transition: all 300ms ease;
      border-radius: 2px;
      box-shadow: 2px 2px 10px rgba($grau, 0.7);
      &:hover {
        background: linear-gradient(lighten($turqouise, 2%), darken($turqouise, 4%));
        box-shadow: 0px 0px 4px rgba(black, 0.7); }
      &:before {
        content: "";
        position: absolute;
        width: calc(100% - 4px);
        height: 50%;
        top: 2px;
        left: 2px;
        background: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.1));
        border-radius: 1px; } } } }

@media (max-width: 600px) {
  #homescreen {
    display: block;
    padding: 30px;
    text-align: center;
    @media (max-width: 300px) {
      padding: 15px; }
    .hometext {
      width: 100%;
      padding: 40px 0;
      .desktop {
        display: none; }
      .mobile {
        display: block; }
      h1 {
        margin: 30px auto 30px;
        @media (max-width: 300px) {
          font-size: 2em; } } }
    .home-images {
      width: 100%;
      display: block;
      border-bottom: 2px solid $grau;
      padding-bottom: 40px;
      border-right: none;
      padding-right: 0;
      .desktop {
        display: none; }
      .mobile {
        display: block;
        max-width: 100%;
        height: auto; } } } }
