@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800);
button.btn {
  color: #fff;
  position: relative;
  background: linear-gradient(#aec8d8, #92b5cb);
  border: 1px solid #9dbcd0;
  padding: 5px 10px 5px 30px;
  font-size: 0.825em;
  text-transform: none;
  letter-spacing: .01em;
  font-weight: 600;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0); }
  button.btn:before {
    content: "";
    width: 18px;
    height: 12px;
    background: url(/static/media/mail.2b6cb31c.svg) center no-repeat;
    background-size: contain;
    position: absolute;
    left: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  button.btn:hover {
    background: linear-gradient(#b5cddb, #a0bfd1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); }

#ergebnis .q-head h2 {
  font-size: 2em;
  max-width: 600px; }

@media (max-width: 680px) {
  #ergebnis .q-head h2 {
    font-size: 1.4em !important;
    max-width: 50%; }
  #ergebnis .q-head .divider {
    width: 50% !important; }
  #ergebnis #ergebnisContent .resultDescription img {
    display: none; }
  #ergebnis .q-head {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    padding-right: 20px !important; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  -webkit-font-smoothing: antialiased; }
  @media (max-width: 300px) {
    * {
      font-size: 12px; } }

* {
  font-family: 'Open Sans', sans-serif; }

#homescreen {
  padding: 40px;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  #homescreen .home-images {
    width: 40%;
    border-right: 2px solid #9a9a9a;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-right: 40px; }
    #homescreen .home-images .mobile {
      display: none; }
  #homescreen .hometext {
    width: 100%;
    padding: 0 40px;
    text-align: center; }
    #homescreen .hometext img {
      max-width: 100%;
      height: auto; }
    #homescreen .hometext .mobile {
      display: none; }
    #homescreen .hometext h3 {
      color: #9a9a9a;
      font-size: 1.6em;
      font-weight: 800;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 2px solid #aec8d8;
      display: inline-block; }
    #homescreen .hometext h1 {
      color: #9a9a9a;
      font-size: 2.8em;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      font-weight: 800;
      line-height: 1.2;
      margin: 40px auto;
      max-width: 100%;
      text-align: center; }
    #homescreen .hometext button.btn-start {
      color: white;
      position: relative;
      background: linear-gradient(#aec8d8, #92b5cb);
      border: 1px solid #9dbcd0;
      padding: 10px 20px;
      font-size: 1.4em;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-weight: 700;
      cursor: pointer;
      transition: all 300ms ease;
      border-radius: 2px;
      box-shadow: 2px 2px 10px rgba(154, 154, 154, 0.7); }
      #homescreen .hometext button.btn-start:hover {
        background: linear-gradient(#b5cddb, #a0bfd1);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7); }
      #homescreen .hometext button.btn-start:before {
        content: "";
        position: absolute;
        width: calc(100% - 4px);
        height: 50%;
        top: 2px;
        left: 2px;
        background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
        border-radius: 1px; }

@media (max-width: 600px) {
  #homescreen {
    display: block;
    padding: 30px;
    text-align: center; } }
  @media (max-width: 600px) and (max-width: 300px) {
    #homescreen {
      padding: 15px; } }

@media (max-width: 600px) {
    #homescreen .hometext {
      width: 100%;
      padding: 40px 0; }
      #homescreen .hometext .desktop {
        display: none; }
      #homescreen .hometext .mobile {
        display: block; }
      #homescreen .hometext h1 {
        margin: 30px auto 30px; } }
      @media (max-width: 600px) and (max-width: 300px) {
        #homescreen .hometext h1 {
          font-size: 2em; } }

@media (max-width: 600px) {
    #homescreen .home-images {
      width: 100%;
      display: block;
      border-bottom: 2px solid #9a9a9a;
      padding-bottom: 40px;
      border-right: none;
      padding-right: 0; }
      #homescreen .home-images .desktop {
        display: none; }
      #homescreen .home-images .mobile {
        display: block;
        max-width: 100%;
        height: auto; } }

.content {
  max-width: 1000px;
  height: 800px;
  background: #edf0f2;
  margin: 0 auto;
  border: 1px solid lightgrey;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
  scroll-behavior: smooth; }
  .content .bullets {
    width: 100%;
    height: 30px;
    background: white;
    position: absolute;
    bottom: 0;
    text-align: center; }
    .content .bullets .bullet-empty {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #9a9a9a;
      display: inline-block;
      margin: 10px 5px; }
    .content .bullets .bullet-full {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #9a9a9a;
      display: inline-block;
      margin: 10px 5px; }

section[class*="frage"] {
  width: 100%;
  margin: 0 auto;
  position: relative; }
  section[class*="frage"] .q-head {
    display: block;
    background: white;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 20px 0 0 30px; }
    section[class*="frage"] .q-head .divider {
      height: 2px;
      width: 300px;
      background: #aec8d8;
      margin: 10px 0 20px 0; }
    @media (max-width: 300px) {
      section[class*="frage"] .q-head {
        padding: 15px 20px; }
        section[class*="frage"] .q-head .divider {
          margin: 10px 0 0 0; } }
    section[class*="frage"] .q-head h2 {
      font-size: 2em;
      text-transform: uppercase;
      color: #9a9a9a;
      width: 100%; }
  section[class*="frage"] ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    list-style: none;
    padding: 20px 0;
    height: 680px; }
    section[class*="frage"] ul li {
      min-width: 100px;
      max-width: 290px;
      width: 30%;
      margin: 10px; }
      section[class*="frage"] ul li div {
        cursor: pointer;
        transition: all 300ms ease; }
        section[class*="frage"] ul li div img {
          max-width: 100%;
          box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3), 0 0 6px rgba(0, 0, 0, 0.4);
          transition: all 300ms ease; }
        section[class*="frage"] ul li div:hover img {
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4); }
        section[class*="frage"] ul li div:active img {
          opacity: 0.5; }
  section[class*="frage"] button {
    padding: 10px 20px;
    border: none;
    background: orangered;
    font-size: 16px;
    color: white;
    cursor: pointer; }

.ergebnis {
  top: 0;
  max-width: 900px;
  margin: 30px auto; }

#sehtyp {
  position: fixed;
  right: 0;
  top: 0;
  background: lightgrey;
  max-width: 300px;
  padding: 30px;
  height: 100vh; }
  #sehtyp h1 {
    margin-bottom: 30px;
    font-size: 2em; }
  #sehtyp p {
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    word-spacing: normal; }

.frage {
  text-align: center; }
  .frage .subtitle {
    margin: 20px auto;
    padding: 6px 13px;
    border: solid 2px #9a9a9a;
    color: #9a9a9a;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block; }
    @media (max-width: 300px) {
      .frage .subtitle {
        font-size: 1em; } }

@media (max-width: 600px) {
  section[class*="frage"] .q-head {
    padding-right: 30px; }
    section[class*="frage"] .q-head h2 {
      font-size: 1.6em;
      line-height: 1.4; } }
    @media (max-width: 600px) and (max-width: 300px) {
      section[class*="frage"] .q-head h2 {
        font-size: 1.2em; } }

@media (max-width: 600px) {
  section[class*="frage"] ul {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    list-style: none;
    padding: 20px 0;
    height: auto; }
    section[class*="frage"] ul li {
      width: 37%;
      max-width: 185px; } }
    @media (max-width: 600px) and (max-width: 300px) {
      section[class*="frage"] ul li {
        width: 42%;
        margin: 5px; }
        section[class*="frage"] ul li img {
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1) !important;
          display: block; } }

@media (max-width: 600px) {
  #q7 ul li, #male ul li, #female ul li {
    width: 45%;
    max-width: 220px;
    margin: 10px auto; }
    #q7 ul li .subtitle, #male ul li .subtitle, #female ul li .subtitle {
      font-size: 1em; } }

#ergebnis .q-head {
  display: block;
  background: white;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 20px 30px 0 30px; }
  #ergebnis .q-head .divider {
    height: 2px;
    width: 300px;
    background: #aec8d8;
    margin: 10px 0 20px 0; }
  #ergebnis .q-head h2 {
    font-size: 2em;
    text-transform: uppercase;
    color: #9a9a9a;
    width: 100%; }
  @media (max-width: 300px) {
    #ergebnis .q-head {
      padding: 15px 20px; }
      #ergebnis .q-head .divider {
        margin: 15px 0 0 0; }
      #ergebnis .q-head h2 {
        margin-bottom: 10px;
        max-width: 100%; } }

#ergebnis #ergebnisContent .resultDescription {
  width: 100%;
  height: auto;
  position: relative;
  padding: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  text-align: left;
  background: #fff; }
  @media (max-width: 300px) {
    #ergebnis #ergebnisContent .resultDescription {
      padding: 0 15px 15px; } }
  #ergebnis #ergebnisContent .resultDescription h1 {
    font-size: 2.4em;
    color: #333;
    text-transform: uppercase;
    max-width: 55%; }
  #ergebnis #ergebnisContent .resultDescription p {
    max-width: 55%;
    margin-top: 10px;
    color: #333;
    line-height: 1.8; }
  #ergebnis #ergebnisContent .resultDescription img {
    position: absolute;
    left: 60%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    height: 200px; }

#ergebnis #ergebnisContent .resultGlasses {
  background: white;
  padding: 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  position: relative; }
  #ergebnis #ergebnisContent .resultGlasses h3 {
    font-size: 1.7em;
    color: #9a9a9a;
    text-transform: uppercase;
    text-align: center; }
    @media (max-width: 300px) {
      #ergebnis #ergebnisContent .resultGlasses h3 {
        font-size: 1.2em; } }
  #ergebnis #ergebnisContent .resultGlasses .resultList {
    list-style: none;
    width: 100%;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    overflow-x: scroll; }
    #ergebnis #ergebnisContent .resultGlasses .resultList li {
      min-width: 310px;
      text-align: center;
      padding: 20px;
      color: #9a9a9a; }
      #ergebnis #ergebnisContent .resultGlasses .resultList li img {
        max-width: 100%;
        max-height: 150px; }
      #ergebnis #ergebnisContent .resultGlasses .resultList li .divider {
        height: 2px;
        width: 50%;
        background: #aec8d8;
        margin: 20px auto; }
      @media (max-width: 300px) {
        #ergebnis #ergebnisContent .resultGlasses .resultList li {
          min-width: 0; }
          #ergebnis #ergebnisContent .resultGlasses .resultList li .divider {
            margin: 5px auto; } }

@media (max-width: 680px) {
  #ergebnis #ergebnisContent .resultDescription {
    height: auto; }
    #ergebnis #ergebnisContent .resultDescription h1, #ergebnis #ergebnisContent .resultDescription p {
      max-width: 100%; }
    #ergebnis #ergebnisContent .resultDescription h1 {
      font-size: 1.6em; }
    #ergebnis #ergebnisContent .resultDescription img {
      display: none; }
  #ergebnis #ergebnisContent .resultGlasses {
    overflow-y: scroll;
    padding: 20px 10px; }
    #ergebnis #ergebnisContent .resultGlasses h3 {
      text-align: center; }
    #ergebnis #ergebnisContent .resultGlasses .resultList {
      display: -webkit-flex;
      display: flex;
      margin-top: 20px;
      overflow: visible; }
      #ergebnis #ergebnisContent .resultGlasses .resultList li {
        display: block;
        padding: 10px;
        text-align: center; }
        #ergebnis #ergebnisContent .resultGlasses .resultList li .image-wrapper {
          width: 100%; }
        #ergebnis #ergebnisContent .resultGlasses .resultList li p {
          text-align: center; }
          #ergebnis #ergebnisContent .resultGlasses .resultList li p strong {
            font-size: 0.925em;
            margin-left: 15px; } }

.modal {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  max-width: 1000px;
  height: 100vh;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 0;
  opacity: 0;
  visibility: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: all 300ms ease;
  padding: 10px; }
  .modal .modal--content {
    transition: all 500ms ease;
    background: #fff;
    padding: 60px 40px 40px;
    border: 1px solid lightgrey;
    position: relative;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
    max-width: 360px; }
    @media (max-width: 300px) {
      .modal .modal--content {
        padding: 60px 20px 20px 20px; } }
    .modal .modal--content #close {
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      all: unset;
      border: none;
      outline: none;
      border-radius: none;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      padding: 5px;
      transition: all 300ms ease;
      color: #aec8d8; }
      .modal .modal--content #close:hover {
        color: orangered; }
    .modal .modal--content form {
      min-width: 240px;
      width: 100%; }
      @media (max-width: 300px) {
        .modal .modal--content form {
          min-width: 100px; } }
      .modal .modal--content form input[type="email"] {
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        all: unset;
        display: block;
        text-align: center;
        width: 100%;
        padding: 5px 10px;
        border-radius: 0;
        border: 1px solid lightgrey;
        font-size: 0.825em;
        box-sizing: border-box; }
        .modal .modal--content form input[type="email"]:focus {
          border-color: #aec8d8;
          color: #aec8d8; }
      .modal .modal--content form input[type="submit"] {
        all: unset;
        -webkit-appearance: none;
           -moz-appearance: none;
                appearance: none;
        font-size: 0.925em;
        text-transform: uppercase;
        display: block;
        padding: 5px 10px;
        background: #aec8d8;
        border: #9dbcd0 1px solid;
        border-radius: 4px;
        color: white;
        cursor: pointer;
        margin: 20px 0 0 auto;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        font-weight: 600; }
  .modal.active {
    opacity: 1;
    visibility: visible; }
    .modal.active .modal--content {
      -webkit-transform: translateY(0);
              transform: translateY(0); }

