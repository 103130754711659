button.btn {
  color: #fff;
  position: relative;
  background: linear-gradient(#aec8d8,#92b5cb);
  border: 1px solid #9dbcd0;
  padding: 5px 10px 5px 30px;
  font-size: 0.825em;
  text-transform: none;
  letter-spacing: .01em;
  font-weight: 600;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: all 200ms ease;
  box-shadow: 0 0 0px rgba(0,0,0,.0);
  // &:after
  //   content: ""
  //   position: absolute
  //   width: calc(100% - 4px)
  //   height: 50%
  //   top: 2px
  //   left: 2px
  //   background: linear-gradient(rgba(255,255,255,.5),rgba(255,255,255,.1))
  //   border-radius: 1px
  &:before {
    content: "";
    width: 18px;
    height: 12px;
    background: url('./../img/mail.svg') center no-repeat;
    background-size: contain;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%); }

  &:hover {
    background: linear-gradient(#b5cddb,#a0bfd1);
    box-shadow: 0 0 4px rgba(0,0,0,.2); } }

#ergebnis .q-head h2 {
  font-size: 2em;
  max-width: 600px; }


@media(max-width: 680px) {

  #ergebnis .q-head h2 {
    font-size: 1.4em!important;
    max-width: 50%; }

  #ergebnis .q-head .divider {
    width: 50%!important; }

  #ergebnis #ergebnisContent .resultDescription img {
    display: none; }


  #ergebnis .q-head {
    justify-content: space-between;
    padding-right: 20px!important; } }
